import { Box, Typography } from "@mui/material";

import { colors } from "../utility/constants";

import { LogoKnobs, LogoBCode } from "../assets/images";

interface PoweredByProps {
  small?: boolean;
}

const PoweredBy: React.FC<PoweredByProps> = ({ small }) => {
  return (
    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
      <Typography color={colors.white} fontSize="1rem" fontWeight="900" sx={{ mr: "0.5rem" }}>
        Powered By
      </Typography>

      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <a href="https://knobs.it" target="_blank" rel="noreferrer">
          {small ? (
            <img alt={"KNOBS Logo"} src={LogoKnobs} style={{ width: 100 }} />
          ) : (
            <img alt={"KNOBS Logo"} src={LogoKnobs} />
          )}
        </a>

        <Typography color={colors.white} fontSize="1rem" fontWeight="900" sx={{ pl: "0.5rem", pr: "0.5rem" }}>
          &
        </Typography>

        <a href="https://bcode.cloud" target="_blank" rel="noreferrer">
          <img alt={"BCode Logo"} src={LogoBCode} />
        </a>
      </Box>
    </Box>
  );
};

export default PoweredBy;
