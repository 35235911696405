import { FC, useCallback, useState } from "react";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";

import Button from "./Button";

import { BlackDinner, MontyScontMale, ReverseWaves } from "../assets/images";

// external components
import PoweredBy from "./PoweredBy";
import AlertText from "./AlertText";
import { getDropTree, Winning } from "../utility/winners";
import { useAccount, useContract, useSigner } from "wagmi";
import { GiveawayContractV2 } from "../contract-types";
import { getCardImageByType, getCardTitleByType, getContractAddresses, getOpenseaLink } from "../utility/utils";
import DROP_ABI from "../abi/GiveawayContractV2_abi.json";

interface DialogCardProps {
  type: "success" | "fail" | "retired";
  open: boolean;
  onClose: () => void;
  onClick?: () => void;
  tokens: Array<Winning>;
  fetchWinnings: (address: string) => Promise<void>;
}

const texts = {
  success: {
    title: "YES",
    buttonText: "Scopri il tuo NFT",
  },
  fail: {
    title: "Azz... no, alla prossima!",
    buttonText: "Vai alla collection",
  },
  retired: {
    title: "Hai già riscattato!",
    buttonText: "Vedi su OpenSea",
  },
  booking: {
    title: "Prenota un'esperienza",
    buttonText: "Prenota",
  },
};

const sendTransaction = async (
  contract: GiveawayContractV2,
  dropNumber: number,
  leafIndex: number,
  address: string,
) => {
  const tree = getDropTree(dropNumber);
  const leaf = `0x${tree.getLeaf(leafIndex).toString("hex")}`;
  const proof = tree.getHexProof(leaf);
  const tx = await contract.claimNFT(dropNumber, proof, leaf, leafIndex, address);
  const receipt = tx.wait();

  return receipt;
};

const DialogCard: FC<DialogCardProps> = ({ open, onClose, type, onClick, tokens, fetchWinnings }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [alert, setAlert] = useState<"success" | "failed" | "booking">();
  const [card, setCard] = useState(0);

  const { data } = useAccount();
  const { data: signer } = useSigner();

  const contract = useContract<GiveawayContractV2>({
    addressOrName: getContractAddresses().drop,
    contractInterface: DROP_ABI,
    signerOrProvider: signer,
  });

  const nextCard = useCallback(() => {
    if (!tokens) return;

    if (card + 1 === tokens.length) {
      setCard(0);
    } else {
      setCard(card + 1);
    }
  }, [card, tokens]);

  const handleClick = useCallback(async () => {
    if (!contract || !tokens || !data) return;

    if (type === "success") {
      if (tokens[card].claimed) {
        window.open(`${getOpenseaLink("assets")}/${getContractAddresses().nft}/${tokens[card].tokenId}`, "_blank");
        return;
      }

      setIsLoading(!isLoading);
      setAlert(undefined);
      try {
        if (data.address) {
          await sendTransaction(contract, tokens[card].dropNumber, tokens[card].leafIndex, data?.address);

          await fetchWinnings(data?.address);
        }
      } catch (e) {
        console.log("transaction error", e);
        setAlert("failed");
      } finally {
        setIsLoading(prevState => !prevState);
      }
    }

    if (type === "fail") {
      window.open(`${getOpenseaLink("collection")}/monty-experiences`, "_blank");
    }
  }, [tokens, isLoading, type, contract, data, card, fetchWinnings]);

  const handleClose = useCallback(() => {
    setAlert(undefined);
    onClose();
  }, [onClose]);

  return (
    <Dialog onClose={handleClose} open={open} sx={{ textAlign: "center" }} fullWidth maxWidth="md">
      <Box
        sx={{
          width: "100%",
          height: "700px",
          color: "white",
          backgroundImage: `url(${ReverseWaves})`,
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "column",
          paddingTop: "1rem",
          paddingBottom: "1rem",
          alignItems: "center",
        }}
      >
        <Box sx={{ flex: 1 }}>
          <Typography fontSize="3rem" fontWeight="900" variant="h4">
            {tokens[card]?.claimed ? getCardTitleByType(tokens[card].type) : texts[type].title}
          </Typography>
        </Box>

        {isLoading && (
          <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}

        {!isLoading && (
          <Box sx={{ display: "flex", flex: 3, width: "100%", alignItems: "center", justifyContent: "center" }}>
            {type === "success" && (
              <>
                {tokens.length > 1 && (
                  <Box sx={{ flex: 1, cursor: "pointer" }}>
                    <FontAwesomeIcon size="6x" icon={faChevronLeft} onClick={nextCard} />
                  </Box>
                )}
                <Box sx={{ display: "flex", width: "100%", flex: 4, flexDirection: "column", alignItems: "center" }}>
                  {tokens[card]?.claimed && (
                    <img
                      src={getCardImageByType(tokens[card].type)}
                      alt="card"
                      style={{ height: "450px", width: "450px" }}
                    />
                  )}

                  {!tokens[card]?.claimed && (
                    <Box
                      sx={{
                        position: "relative",
                        width: "100%",
                        height: "100%",
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Box component="img" src={BlackDinner} sx={{ height: "450px" }} />
                      </Box>
                      <Box
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography color="white" fontSize="8rem" fontWeight="900">
                          ?
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                {tokens.length > 1 && (
                  <Box sx={{ flex: 1, cursor: "pointer" }}>
                    <FontAwesomeIcon size="6x" icon={faChevronRight} onClick={nextCard} />
                  </Box>
                )}
              </>
            )}

            {type === "fail" && (
              <Box sx={{ display: "flex", width: "100%", flex: 4, flexDirection: "column", alignItems: "center" }}>
                <img src={MontyScontMale} alt="card" />
              </Box>
            )}
          </Box>
        )}

        <Box sx={{ display: "flex", flex: 1 }}>{alert ? <AlertText type={alert} /> : <PoweredBy />}</Box>

        <Box sx={{ flex: 1, display: "flex" }}>
          <Button
            text={texts[tokens[card]?.claimed ? "retired" : type].buttonText}
            size="large"
            disabled={isLoading}
            onClick={handleClick}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogCard;
