import { BigNumber, ethers } from "ethers";
import { computeTree, getHashedLeaves } from "./merkletree";

/**
 * @description List of all the NFTs in the same order as they has been
 * sent to the drop contract
 */
const tokenIds = [
  76, 92, 80, 85, 99, 67, 23, 57, 15, 83, 98, 49, 30, 90, 14, 36, 3, 45, 40, 93, 17, 46, 7, 91, 94, 43, 52, 105, 42, 29,
  55, 10, 5, 100, 62, 11, 27, 58, 16, 35, 64, 79, 22, 103, 37, 65, 13, 51, 1, 72, 96, 74, 110, 33, 78, 108, 102, 50, 66,
  53, 4, 54, 2, 6, 25, 86, 73, 109, 104, 70, 107, 31, 28, 32, 9, 71, 24, 69, 59, 95, 34, 47, 56, 20, 44, 89, 61, 101,
  82, 84, 18, 68, 38, 39, 88, 12, 60, 21, 81, 63, 75, 19, 106, 8, 41, 26, 48, 77, 87, 97,
];

const dropFolder = process.env?.REACT_APP_DROP_FOLDER || "drop";

/**
 * @description Drop configuration. Contains all the addresses for each single drop, the
 * number of participants and the number of prizes for that specific drop
 */
export const dropConfigs = [
  {
    participants: require(`./${dropFolder}/1_drop.json`),
    numberOfParticipants: 41,
    numberOfWinners: 41,
  },
  {
    participants: require(`./${dropFolder}/2_drop.json`),
    numberOfParticipants: 57,
    numberOfWinners: 9,
  },
  {
    participants: require(`./${dropFolder}/3_drop.json`),
    numberOfParticipants: 215,
    numberOfWinners: 50,
  },
  {
    participants: require(`./${dropFolder}/4_drop.json`),
    numberOfParticipants: 1021,
    numberOfWinners: 10,
  },
];

/**
 * @description Compute the number of NFTs for the complete drop
 */
export const totalNfts = dropConfigs.reduce((sum, el) => sum + el.numberOfWinners, 0);

/**
 * @description Utility function that adjust the leaf index by the start index
 * and the number of participants (it automatically restarts from 0 when the array ends)
 * @param {number} index index to adjust
 * @param {number} startIndex start index for this specific drop
 * @param {number} participants number of participants for this drop
 * @returns
 */

export function adjustLeafIndex(index: number, startIndex: number, participants: number) {
  const difference = index - startIndex;
  const remaining = participants - startIndex;

  if (difference >= 0) {
    return difference;
  } else {
    return remaining + index;
  }
}

/**
 * @description
 * @param {number} index
 * @param {number} startIndex
 * @param {number} dropLength
 * @returns
 */
export function adjustNFTIndex(index: number, startIndex: number, dropLength: number) {
  if (startIndex + index >= dropLength) {
    return (startIndex + index) % dropLength;
  }

  return startIndex + index;
}

/**
 * @description Computes the number of NFTs that belongs to previous drops
 * @param {number} dropIndex Index of the current drop
 * @returns the number of NFTs that belongs to previous drops
 */
const precDropNfts = (dropIndex: number) =>
  dropIndex === 0
    ? 0
    : dropConfigs.filter((_, i) => i < dropIndex).reduce((sum, dropConfig) => sum + dropConfig.numberOfWinners, 0);

/**
 * @description Data structure for getting the type of prize by the token id
 */
const matches: Array<WinningsTypes> = ["tennis-match", "table-tennis-match", "dinner", "lunch", "breakfast"];

const calls: Array<{ min: number; max: number; type: WinningsTypes }> = [
  { min: 6, max: 15, type: "60-min-call" },
  { min: 16, max: 35, type: "30-min-call" },
  { min: 36, max: 65, type: "10-min-call" },
  { min: 66, max: 110, type: "5-min-call" },
];

export type WinningsTypes =
  | "5-min-call"
  | "10-min-call"
  | "30-min-call"
  | "60-min-call"
  | "breakfast"
  | "lunch"
  | "dinner"
  | "tennis-match"
  | "table-tennis-match"
  | "non-existent";

/**
 * @description Returns the token types the user won
 * @param {number} tokenId The token id to check
 * @returns an array of string representing the token types
 */
export function tokenTypeById(tokenId: number) {
  if (matches[tokenId]) return matches[tokenId];

  return calls.find(call => tokenId >= call.min && tokenId <= call.max)?.type || "non-existent";
}

export type Winning = {
  tokenId: number;
  dropNumber: number;
  leafIndex: number;
  type: WinningsTypes;
  indexToClaim: number;
  claimed?: boolean;
};
/**
 * @description returns the winnings of a specific address
 * @param {string} address address to check
 * @param {string[]} randomness Randomness received from Chainlink VRF
 * @param {number} numberOfNfts Total amount of NFTs for the whole drop
 * @returns an array of token types
 */
export function whatDidIWin(address: string, randomness: string[], numberOfNfts: number) {
  const ids: Array<Winning> = [];
  dropConfigs.forEach((dropConfig, dropNumber) => {
    const index = dropConfig.participants.findIndex(
      (participant: string) => ethers.utils.getAddress(participant) === ethers.utils.getAddress(address),
    );

    if (index === -1) return;

    const startNft = BigNumber.from(randomness[0]).mod(numberOfNfts).toNumber();

    const startIndex = BigNumber.from(randomness[1]).mod(dropConfig.numberOfParticipants).toNumber();

    const adjustedLeafIndex = adjustLeafIndex(index, startIndex, dropConfig.numberOfParticipants);

    if (adjustedLeafIndex < dropConfig.numberOfWinners) {
      const indexToClaim = adjustNFTIndex(adjustedLeafIndex + precDropNfts(dropNumber), startNft, totalNfts);

      ids.push({
        tokenId: tokenIds[indexToClaim],
        dropNumber,
        leafIndex: index,
        type: tokenTypeById(tokenIds[indexToClaim]),
        indexToClaim,
      });
    }
  });

  return ids;
}

export function getDropTree(dropNumber: number) {
  return computeTree(getHashedLeaves(dropConfigs[dropNumber].participants));
}
