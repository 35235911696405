import { useCallback, useState } from "react";
import { useConnect, useDisconnect, useNetwork } from "wagmi";
import { useNavigate, Link } from "react-router-dom";

import { useApp } from "../context/app.context";

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Button from "./Button";
import DialogConnect from "./DialogConnect";
import Tooltip from "./Tooltip";

import { LogoMontyLab } from "../assets/images";

const Header = () => {
  const [openConnect, setOpenConnect] = useState(false);

  const {
    state: { showLoginTooltip },
  } = useApp();

  const { isConnecting, isConnected } = useConnect();
  const { disconnect } = useDisconnect();

  const { activeChain } = useNetwork();

  const navigate = useNavigate();

  const handleConnectDialog = useCallback(() => {
    setOpenConnect(true);
  }, []);

  const handleCloseConnect = useCallback(() => {
    setOpenConnect(false);
  }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: "1",
      }}
    >
      <DialogConnect open={openConnect} onClose={handleCloseConnect} />

      {activeChain?.unsupported && (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            width: "100%",
            height: "2rem",
            background: "#e96666",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "1rem",
          }}
        >
          <Typography sx={{ color: "white", fontWeight: "bold" }}>
            Sei connesso alla rete sbagliata! Connetti il wallet ad Ethereum Mainnet
          </Typography>
        </Box>
      )}

      <Box sx={{ position: "relative", display: "flex", justifyContent: "space-between", padding: "3rem 8rem" }}>
        <Link to="/">
          <img src={LogoMontyLab} alt="Logo Montylab" />
        </Link>

        {isConnecting ? null : !isConnected ? (
          <Box sx={{ position: "relative" }}>
            <Button text="Login" onClick={handleConnectDialog} />
            {!isConnected && showLoginTooltip ? <Tooltip /> : null}
          </Box>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Button text="Prenota" onClick={() => navigate("/experiences")} />
            <Button
              text="Esci"
              onClick={() => {
                disconnect();
              }}
              otherSx={{ marginLeft: "1rem" }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Header;
