import { useCallback, useState } from "react";
import { useConnect, useAccount, useNetwork, useContract, useProvider } from "wagmi";
import { Keyboard, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

import { useApp } from "../context/app.context";

import Box from "@mui/material/Box";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import Button from "../components/Button";
import CardDialog from "../components/DialogCard";
import CardExperience from "../components/CardExperience";
import PoweredBy from "../components/PoweredBy";

import { HeroMonty, Waves } from "../assets/images";
import { colors, montyExperiences } from "../utility/constants";
import { getContractAddresses, getWinnings } from "../utility/utils";
import { Winning } from "../utility/winners";
import { GiveawayContractV2 } from "../contract-types";

import DROP_ABI from "../abi/GiveawayContractV2_abi.json";
import "swiper/css";

const Home = () => {
  const [swiperInstance, setSwiperInstance] = useState<any>(null);
  const [isScrollDirectionNext, setIsScrollDirectionNext] = useState(true);
  const [spinLoad, setSpinLoad] = useState(false);

  const [openCard, setOpenCard] = useState<{
    winnings: Array<Winning>;
    opened: boolean;
    type: "success" | "fail";
  }>({
    winnings: [],
    opened: false,
    type: "success",
  });

  const { dispatch } = useApp();

  const provider = useProvider();
  const { isConnected } = useConnect();
  const { data } = useAccount();
  const { activeChain } = useNetwork();

  const contract = useContract<GiveawayContractV2>({
    addressOrName: getContractAddresses().drop,
    contractInterface: DROP_ABI,
    signerOrProvider: provider,
  });

  const handleScroll = () => {
    if (swiperInstance) {
      if (isScrollDirectionNext) {
        swiperInstance.slideNext();
        return;
      }

      swiperInstance.slidePrev();
    }
  };

  const onScrollChange = () => {
    if (swiperInstance) {
      if (swiperInstance.progress === 1) {
        setIsScrollDirectionNext(false);
      }

      if (swiperInstance.progress === 0) {
        setIsScrollDirectionNext(true);
      }
    }
  };

  const fetchWinnings = useCallback(
    async (address: string) => {
      const winnings = await getWinnings(address, contract);

      if (winnings?.length) {
        setOpenCard({ winnings, opened: true, type: "success" });
        return;
      }

      setOpenCard({ winnings: [], opened: true, type: "fail" });
    },
    [contract],
  );

  const handleCheck = useCallback(async () => {
    if (!isConnected) {
      dispatch({ type: "SET_SHOW_LOGIN_TOOLTIP", payload: true });
      return;
    }

    if (!data?.address) return;

    setSpinLoad(true);

    await fetchWinnings(data.address);

    setSpinLoad(false);
  }, [data?.address, isConnected, fetchWinnings]);

  const handleCloseCard = useCallback(() => {
    setOpenCard({ winnings: [], opened: false, type: "success" });
  }, []);

  return (
    <>
      <Backdrop sx={{ color: "#fff", zIndex: theme => theme.zIndex.drawer + 1 }} open={spinLoad}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <CardDialog
        type={openCard.type}
        open={openCard.opened}
        onClose={handleCloseCard}
        tokens={openCard.winnings}
        fetchWinnings={fetchWinnings}
      />

      <Box sx={{ minHeight: "800px", position: "relative" }}>
        <Box
          component="img"
          src={Waves}
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            background: "radial-gradient(100% 100% at 50% 0%, #47B288 0%, #37B174 100%)",
          }}
        />
        <Box component="img" src={HeroMonty} sx={{ position: "absolute", right: 0, bottom: 0 }} />

        <Box sx={{ width: "45%", position: "relative", padding: "8rem" }}>
          <Typography variant="h1" sx={{ color: "white", fontWeight: "bold" }}>
            Vuoi sfidarmi a ping pong?
          </Typography>

          <Typography
            variant="h2"
            sx={{
              color: "white",
              fontSize: "1.5rem",
              fontWeight: "400",
              lineHeight: "1.334",
              mt: "1rem",
            }}
          >
            Gli NFT, riservati ai possessori di Crazy Fury, per sfidare Monty a Tennis/Ping Pong, andarci a cena o
            raccontargli il tuo progetto!
          </Typography>

          <Button
            text="Sei la/il fortunata/o?"
            size="large"
            otherSx={{ mt: "3rem", fontSize: "1.5rem" }}
            onClick={handleCheck}
            disabled={Boolean(activeChain?.unsupported)}
          />

          <Box sx={{ mt: "3rem" }}>
            <PoweredBy />
          </Box>
        </Box>
      </Box>

      <Box sx={{ height: "600px", backgroundColor: colors.primary, position: "relative" }}>
        <Box sx={{ transform: "translateY(-6rem)" }}>
          <Swiper
            onInit={swiper => setSwiperInstance(swiper)}
            onProgress={swiper => {
              setSwiperInstance(swiper);
              onScrollChange();
            }}
            slidesPerView="auto"
            navigation={true}
            grabCursor={true}
            keyboard={{ enabled: true }}
            modules={[Keyboard, Navigation]}
          >
            {montyExperiences.map(experience => (
              <SwiperSlide key={experience.title}>
                <CardExperience
                  key={experience.title}
                  image={experience.image}
                  title={experience.title}
                  description={experience.description}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <Box sx={{ position: "absolute", bottom: "8rem", right: "14rem", cursor: "pointer" }} onClick={handleScroll}>
          <FontAwesomeIcon
            style={{
              transform: `${!isScrollDirectionNext ? "rotateY(180deg)" : "rotate(0)"}`,
              transition: "transform 200ms ease",
            }}
            icon={faArrowRightLong}
            size="5x"
            color="white"
          />
        </Box>

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            text="Che fai, non provi?"
            size="large"
            onClick={handleCheck}
            otherSx={{ position: "absolute", bottom: "5rem", fontSize: "1.5rem" }}
            disabled={Boolean(activeChain?.unsupported)}
          />
        </Box>
      </Box>
    </>
  );
};

export default Home;
