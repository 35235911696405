import { Container, Box, Typography, Link } from "@mui/material";
import { colors } from "../utility/constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDiscord,
  faInstagram,
  faMedium,
  faTwitter,
  faFacebook,
  faYoutube,
  IconDefinition,
} from "@fortawesome/free-brands-svg-icons";

import { LogoMontyLab } from "../assets/images";

const socials: { [key: string]: { icon: IconDefinition; link: string } } = {
  discord: {
    icon: faDiscord,
    link: "https://discord.gg/montemagno",
  },
  instagram: {
    icon: faInstagram,
    link: "https://www.instagram.com/marcomontemagno/",
  },
  medium: {
    icon: faMedium,
    link: "https://medium.com/@montemagno",
  },
  twitter: {
    icon: faTwitter,
    link: "https://twitter.com/montemagno",
  },
  facebook: {
    icon: faFacebook,
    link: "https://it-it.facebook.com/montemagno",
  },
  youtube: {
    icon: faYoutube,
    link: "https://www.youtube.com/user/montymonty",
  },
};

const informations: { [key: string]: { title: string; link: string } } = {
  privacy: {
    title: "Privacy Policy",
    link: "https://docs.google.com/document/d/e/2PACX-1vR37iBd_OQ1tDV7UhN3qMM8x2G775GVdgtE7aw2jBNek84i9D65WADgKMcQ2Zyczw/pub",
  },
  cookies: {
    title: "Cookie Policy",
    link: "https://docs.google.com/document/d/e/2PACX-1vRRGA3YMzT0Fhw9jwC7KF5IXMgnLWoRRHs7VOweCSzXFHcEnWV9_nvMoihRRclu_A/pub",
  },
  "condition-terms": {
    title: "Termini e Condizioni",
    link: "https://docs.google.com/document/d/e/2PACX-1vT4N1Efr98jfFa3rEP9rbhAD0CWmX9-FCUIAoWv299JtnQe4sGT1eAX0mi-Kdbj3W-G12x97tp9oqFK/pub",
  },
  "use-terms": {
    title: "Termini d'Utilizzo",
    link: "https://docs.google.com/document/d/e/2PACX-1vQk5tnbh04lrPwzZ_tYadUOD0pFponnkzWA1a7FkcVDN_D6nJHfAsCN6tmAtACHqKGvTkkIC6w18cf6/pub",
  },
};

interface FooterProps {}

const Footer: React.FC<FooterProps> = () => {
  return (
    <Container style={{ maxWidth: "100%", backgroundColor: colors.footer, padding: "6rem 20rem 4rem 20rem" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
        <Box width="33%">
          <Box component="img" src={LogoMontyLab} />

          <Typography color={colors.white} mt="1rem">
            TECH ALCHEMIST LTD
          </Typography>

          <Typography color={colors.white}>Tech Alchemist Ltd Old Casino, 28 Fourth</Typography>
          <Typography color={colors.white}>Avenue Hove, East Sussex BN3 2PJ United Kingdom</Typography>
          <Typography color={colors.white}>VAT# : GB164846382</Typography>
          <Typography color={colors.white}>Reg. No: 8292693 | Reg. in england and wales</Typography>
        </Box>

        <Box width="33%">
          <Typography color={colors.white} fontWeight="bold" fontSize="1.188rem">
            Informazioni
          </Typography>

          {Object.keys(informations).map((key: string, i) => {
            const isNotFirstElement = i !== 0;

            return (
              <Typography key={key} mt={isNotFirstElement ? "0.5rem" : "1.5rem"}>
                <Link href={informations[key].link} color="#fff" underline="none" target="_blank">
                  {informations[key].title}
                </Link>
              </Typography>
            );
          })}
        </Box>

        <Box width="33%">
          <Typography color={colors.white} fontWeight="bold" fontSize="1.188rem">
            NFT-Montemagno
          </Typography>

          <Typography color={colors.white} mt="1.5rem">
            Seguici sui social
          </Typography>

          <Box mt="1.5rem" display="flex">
            {Object.keys(socials).map((key: string, i) => {
              const isNotFirstElement = i !== 0;

              return (
                <Box key={key} ml={isNotFirstElement ? "1rem" : "0"}>
                  <Link href={socials[key].link} color={colors.white} target="_blank">
                    <FontAwesomeIcon icon={socials[key].icon} />
                  </Link>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>

      <Box mt="5rem">
        <Typography color={colors.white} fontSize="0.938rem">
          L'acquisto degli NFT Crazy Fury potrà essere effettuato tramite OpenSea.com. Una volta acquistato un NFT Crazy
          Fury, i titolari potranno - a propria discrezione - decidere di registrarsi alla community MontyLab tramite il
          sito marcomontemagno.com. I vantaggi descritti nel video, che potranno essere modificati nel tempo a
          discrezione della Tech Alchemist Ltd, saranno disponibili solo per la community MontyLab e sono soggetti a
          limitazioni. Per l'acquisto degli NFT e la registrazione alla community MontyLab si rimanda ai termini e
          condizioni sui siti indicati.
        </Typography>
      </Box>
    </Container>
  );
};

export default Footer;
