import { useState, useCallback, FC, useMemo, useEffect } from "react";
import { useAccount, useConnect, useContract, useProvider } from "wagmi";
import { Navigate } from "react-router-dom";
import { useQuery } from "react-query";
import { BigNumber } from "ethers";
import { query, collection, where } from "firebase/firestore";
import { useFirestoreQuery } from "@react-query-firebase/firestore";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";

import Button from "../components/Button";
import CardExperienceBooking from "../components/CardExperienceBooking";
import DialogExperienceBooking from "../components/DialogExperienceBooking";

import { NFTExp } from "../contract-types";

import { getCardTitleByType, getOpenseaLink, getContractAddresses } from "../utility/utils";
import { colors } from "../utility/constants";
import { db } from "../utility/firebase";
import { getOwnedNFTs } from "../utility/alchemy";
import { tokenTypeById, WinningsTypes } from "../utility/winners";

import { MontyScontMale, Waves } from "../assets/images";

import NFTExp_ABI from "../abi/NFTExp_abi.json";

export type Experience = {
  id: string;
  title: string;
  description: string;
  type: WinningsTypes;
};

interface ExperiencesShowcaseProps {
  setOpenDialog: (value: boolean) => void;
  setSelectedExperience: (value: { id: string; title: string; description: string; type: WinningsTypes }) => void;
  ownedExperiences: Experience[];
}

const ExperiencesShowcase: FC<ExperiencesShowcaseProps> = ({
  setOpenDialog,
  setSelectedExperience,
  ownedExperiences,
}) => {
  const handleOpenDialog = useCallback(() => {
    setOpenDialog(true);
  }, [setOpenDialog]);

  const isSingleExperience = useMemo(() => ownedExperiences.length === 1, [ownedExperiences]);

  return ownedExperiences.length === 0 ? (
    <Box
      sx={{
        position: "relative",
        padding: "8rem 8rem 4rem 8rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
      }}
    >
      <Box component="img" src={MontyScontMale} />

      <Typography color="white" variant="h2" fontWeight="bold" fontSize="4.125rem" sx={{ marginTop: "2rem" }}>
        Non hai nessuna esperienza!
      </Typography>

      <Button
        text="Vai su OpenSea"
        size="large"
        otherSx={{ marginTop: "2rem" }}
        onClick={() => window.open(`${getOpenseaLink("collection")}/monty-experiences`, "_blank")}
      />
    </Box>
  ) : (
    <Box sx={{ position: "relative", padding: "8rem 8rem 4rem 8rem" }}>
      <Typography color="white" variant="h2" fontWeight="bold" fontSize="4.125rem">
        Prenota la tua esperienza
      </Typography>

      <Grid
        container
        rowSpacing={isSingleExperience ? 1 : 2}
        columnSpacing={isSingleExperience ? 1 : 8}
        direction="row"
        justifyContent={isSingleExperience ? "start" : "center"}
        sx={{ marginTop: "2rem" }}
      >
        {ownedExperiences.map(experience => (
          <CardExperienceBooking
            key={experience.title}
            experience={experience}
            isSingleExperience={isSingleExperience}
            openDialog={handleOpenDialog}
            setSelectedExperience={setSelectedExperience}
          />
        ))}
      </Grid>
    </Box>
  );
};

const Experiences = () => {
  const [openDialog, setOpenDialog] = useState(false);

  const [selectedExperience, setSelectedExperience] = useState<Experience>({
    id: "",
    title: "",
    description: "",
    type: "non-existent",
  });

  const provider = useProvider();
  const contract = useContract<NFTExp>({
    addressOrName: getContractAddresses().nft,
    contractInterface: NFTExp_ABI,
    signerOrProvider: provider,
  });

  const { data: account } = useAccount();
  const { isConnecting, isReconnecting, isDisconnected } = useConnect();

  const { data: queryResult, isLoading: isQueryResultLoading } = useQuery(
    `ownedby:${account?.address}`,
    () => getOwnedNFTs(account?.address || ""),
    {
      enabled: Boolean(account?.address),
    },
  );

  const handleCloseDialog = useCallback(() => {
    setSelectedExperience({
      id: "",
      title: "",
      description: "",
      type: "non-existent",
    });
    setOpenDialog(false);
  }, []);

  const bookingsRef = query(
    collection(db, "bookings"),
    where("user.address", "==", account?.address || ""),
    where("confirmed", "==", true),
  );
  const { data: bookingsQueryData, isLoading: isBookingsQueryLoading } = useFirestoreQuery(["bookings"], bookingsRef);

  const loading = isConnecting || isReconnecting || isQueryResultLoading || isBookingsQueryLoading;
  const redirect = isDisconnected;

  const ownedExperiences = useMemo<Experience[]>(() => {
    let result: any[] = [];

    if (bookingsQueryData && contract) {
      const confirmedExperiences = bookingsQueryData.docs.map(docSnapshot => {
        return docSnapshot.id;
      });

      confirmedExperiences.map(experienceId => {
        const type = tokenTypeById(Number(experienceId));
        return result.push({ id: experienceId, title: getCardTitleByType(type), description: "", type });
      });
    }

    if (queryResult?.ownedNfts) {
      queryResult?.ownedNfts.map((ownedNFT: any) => {
        const tokenId = BigNumber.from(ownedNFT.id.tokenId);
        const type = tokenTypeById(tokenId.toNumber());
        return result.push({ id: tokenId.toString(), title: getCardTitleByType(type), description: "", type });
      });
    }

    return result;
  }, [queryResult?.ownedNfts, bookingsQueryData, contract]);

  return (
    <Box sx={{ minHeight: "100vh", position: "relative", backgroundColor: colors.primary }}>
      {openDialog && (
        <DialogExperienceBooking open={openDialog} onClose={handleCloseDialog} experience={selectedExperience} />
      )}

      <Box
        component="img"
        src={Waves}
        sx={{
          width: "100%",
          height: "100%",
          position: "absolute",
          background: `radial-gradient(100% 100% at 50% 0%, ${colors.primary} 0%, ${colors.primary} 100%)`,
        }}
      />
      {loading ? (
        <Box sx={{ position: "absolute", top: "50%", left: "50%", transform: "translate(-50%, -50%)", color: "white" }}>
          <CircularProgress color="inherit" size={60} />
        </Box>
      ) : redirect ? (
        <h2 style={{ position: "relative" }}>
          <Navigate to="/" replace={true} />
        </h2>
      ) : (
        <ExperiencesShowcase
          setOpenDialog={setOpenDialog}
          setSelectedExperience={setSelectedExperience}
          ownedExperiences={ownedExperiences}
        />
      )}
    </Box>
  );
};

export default Experiences;
