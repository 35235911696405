import { useEffect } from "react";
import { useConnect } from "wagmi";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import { LogoCoinbaseWallet, LogoMetamask, LogoWalletConnect } from "../assets/images";

const connectorImages = {
  MetaMask: LogoMetamask,
  "Wallet Connect": LogoWalletConnect,
  "Coinbase Wallet": LogoCoinbaseWallet,
};

interface DialogConnectProps {
  open: boolean;
  onClose: () => void;
}

const DialogConnect = (props: DialogConnectProps) => {
  const { onClose, open } = props;
  const { connectors, connect, isConnected } = useConnect();

  useEffect(() => {
    if (isConnected) {
      onClose();
    }
  }, [isConnected, onClose]);

  return (
    <Dialog onClose={onClose} open={open} sx={{ textAlign: "center" }}>
      <DialogTitle>
        <Typography variant="body1" fontSize="2.25rem" fontWeight="900">
          Seleziona il tuo wallet
        </Typography>
      </DialogTitle>

      <Box sx={{ width: "500px", height: "300px", padding: "0 2rem", display: "flex", flexDirection: "column" }}>
        {connectors.map(connector => {
          console.log(connector.name);
          const src = connectorImages[connector.name as keyof typeof connectorImages] || LogoWalletConnect;

          return (
            <Box
              sx={{
                height: "60px",
                backgroundColor: "#f2f2f2",
                margin: "0.5rem 0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                padding: "0.5rem",
              }}
              onClick={() => connect(connector)}
              key={connector.id}
            >
              <Box component="img" src={src} sx={{ width: "300px", cursor: "pointer" }} />
            </Box>
          );
        })}
      </Box>
    </Dialog>
  );
};

export default DialogConnect;
