import { BrowserRouter, Routes, Route } from "react-router-dom";

import Layout from "./components/Layout";
import Home from "./pages/Home";
import Experiences from "./pages/Experiences";

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout route={Home} />} />
          <Route path="/experiences" element={<Layout route={Experiences} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
};

export default App;
