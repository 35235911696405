import * as yup from "yup";

const validateEmail = (errorMessage: string) => yup.string().email(errorMessage);
const required = (validation: yup.AnySchema, errorMessage: string) => validation.required(errorMessage);

const useValidation = () => ({
  validateEmail: () => validateEmail("E-Mail non valida"),
  required: (validation: yup.AnySchema) => required(validation, "Il campo è obbligatorio"),
});

export default useValidation;
