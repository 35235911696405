import { constants } from "ethers";
import { solidityKeccak256 } from "ethers/lib/utils";
import { MerkleTree } from "merkletreejs";

export const hashedZeroAddress = solidityKeccak256(["address"], [constants.AddressZero]);

export const computeTree = (leaves: string[]) =>
  new MerkleTree(leaves, (data: Buffer) =>
    solidityKeccak256(["bytes32", "bytes32"], [data.slice(0, 32), data.slice(32)]),
  );

export const getHashedLeaves = (addresses: string[]) =>
  new Array(16384)
    .fill(hashedZeroAddress)
    .map((el, i) => (addresses[i] ? solidityKeccak256(["address"], [addresses[i]]) : el));
