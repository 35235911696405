import { useMedia } from "react-use";
import Footer from "./Footer";
import Mobile from "./Mobile";
import Header from "./Header";

interface LayoutProps {
  route: (props: any) => JSX.Element;
}

const Layout: React.FC<LayoutProps> = ({ route }) => {
  const isDesktop = useMedia("(min-width:961px)");
  const Component = route;

  return isDesktop ? (
    <>
      <Header />
      <Component />
      <Footer />
    </>
  ) : (
    <Mobile />
  );
};

export default Layout;
