import React from "react";
import { Container, Box, Typography } from "@mui/material";

interface CardExperienceProps {
  image: string;
  title: string;
  description: string;
  style?: React.CSSProperties;
}

const CardExperience: React.FC<CardExperienceProps> = ({ image, title, description, style }) => {
  return (
    <Container sx={{ width: "250px", height: "500px", padding: "0 !important", margin: "0 2.5rem" }} style={style}>
      <Box component="img" sx={{ width: 250 }} alt={title} src={image} />

      <Box sx={{ marginTop: "1.5rem", width: "100%" }}>
        <Typography color="white" variant="h2" fontWeight="bold" fontSize="1.5rem" lineHeight={1.4}>
          {title}
        </Typography>

        <Typography color="white" variant="body1">
          {description}
        </Typography>
      </Box>
    </Container>
  );
};

export default CardExperience;
