import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import { Waves } from "../assets/images";

const Mobile = () => {
  return (
    <Box sx={{ width: "100vw", height: "100vh" }}>
      <Box
        component="img"
        src={Waves}
        sx={{
          height: "100%",
          background: "radial-gradient(100% 100% at 50% 0%, #47B288 0%, #37B174 100%)",
        }}
      />
      <Box
        sx={{
          width: "100%",
          height: "100%",
          top: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            color: "white",
            fontWeight: "bold",
            textAlign: "center",
            padding: "2rem",
          }}
        >
          Questa applicazione è disponibile solo su desktop.
          <br />
          <br />
          Collegati dal computer, puoi comunque collegare il wallet che hai sul telefono usando WalletConnect!
        </Typography>
      </Box>
    </Box>
  );
};

export default Mobile;
