import { cardTitles } from "./constants";
import { BlackDinner, Breakfast, Lunch, Dinner, Pingpong, Tennis, Videocall } from "../assets/images";
import { totalNfts, whatDidIWin, Winning, WinningsTypes } from "./winners";
import { GiveawayContractV2 } from "../contract-types";

export function getContractAddresses() {
  return {
    drop: process.env?.["REACT_APP_DROP_CONTRACT_ADDRESS"] || "",
    nft: process.env?.["REACT_APP_NFT_CONTRACT_ADDRESS"] || "",
  };
}

export function getCardImageByType(type: WinningsTypes) {
  switch (type) {
    case "breakfast":
      return Breakfast;
    case "lunch":
      return Lunch;
    case "dinner":
      return Dinner;
    case "table-tennis-match":
      return Pingpong;
    case "tennis-match":
      return Tennis;
    case "5-min-call":
      return Videocall;
    case "10-min-call":
      return Videocall;
    case "30-min-call":
      return Videocall;
    case "60-min-call":
      return Videocall;
    default:
      return BlackDinner;
  }
}

export function getCardTitleByType(type: WinningsTypes) {
  return cardTitles[type];
}

export function getOpenseaLink(type: "assets" | "collection") {
  return `${process.env.REACT_APP_OPENSEA_URL || "https://opensea.io"}/${type}`;
}

export const truncAddress = (address: string | undefined) => {
  if (!address) return "";
  return address.substring(0, 6) + "..." + address.substring(address.length - 4);
};

export const getWinnings = async (address: string, contract: GiveawayContractV2) => {
  if (!contract) return;

  const r0 = await contract.s_randomWords("0");
  const r1 = await contract.s_randomWords("1");

  async function extendWithClaimed(winning: Winning): Promise<Winning> {
    const nftData = await contract.nftToDrop(winning.indexToClaim);

    return { ...winning, claimed: nftData.claimed };
  }

  const winnings = await Promise.all(
    whatDidIWin(address, [r0.toString(), r1.toString()], totalNfts).map(winning => extendWithClaimed(winning)),
  );

  return winnings;
};
