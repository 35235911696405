import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";

import Input from "./Input";

import { colors } from "../utility/constants";
import { Typography } from "@mui/material";

interface FormExperienceBookingProps {
  errors: any;
  register: any;
}

const FormExperienceBooking: React.FC<FormExperienceBookingProps> = ({ errors, register }) => {
  const {
    name: fieldNameName,
    onBlur: fieldNameOnBlur,
    onChange: fieldNameOnChange,
    ref: fieldNameInputRef,
  } = register("name");

  const {
    name: fieldEmailName,
    onBlur: fieldEmailOnBlur,
    onChange: fieldEmailOnChange,
    ref: fieldEmailInputRef,
  } = register("email");

  const {
    name: fieldPhoneName,
    onBlur: fieldPhoneOnBlur,
    onChange: fieldPhoneOnChange,
    ref: fieldPhoneInputRef,
  } = register("phone");

  const {
    name: fieldInfoName,
    onBlur: fieldInfoOnBlur,
    onChange: fieldInfoOnChange,
    ref: fieldInfoInputRef,
  } = register("info");

  const {
    name: fieldTermsAndConditionsName,
    onBlur: fieldTermsAndConditionsOnBlur,
    onChange: fieldTermsAndConditionsOnChange,
    ref: fieldTermsAndConditionsInputRef,
  } = register("termsAndConditions");

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: colors.footer,
        borderRadius: "1rem",
        padding: "1rem",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Input
          label="Nome"
          sx={{ width: "100%" }}
          name={fieldNameName}
          onBlur={fieldNameOnBlur}
          onChange={fieldNameOnChange}
          inputRef={fieldNameInputRef}
        />
        {errors.name && (
          <Typography sx={{ color: colors.pink, mt: "0.2rem" }} fontSize="0.8rem">
            {errors.name.message}
          </Typography>
        )}

        <Box sx={{ display: "flex", mt: "0.5rem", width: "100%" }}>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%", mr: "0.5rem" }}>
            <Input
              label="Email"
              sx={{ width: "100%" }}
              name={fieldEmailName}
              onBlur={fieldEmailOnBlur}
              onChange={fieldEmailOnChange}
              inputRef={fieldEmailInputRef}
            />
            {errors.email && (
              <Typography sx={{ color: colors.pink, mt: "0.2rem" }} fontSize="0.8rem">
                {errors.email.message}
              </Typography>
            )}
          </Box>

          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Input
              label="Telefono"
              sx={{ width: "100%" }}
              name={fieldPhoneName}
              onBlur={fieldPhoneOnBlur}
              onChange={fieldPhoneOnChange}
              inputRef={fieldPhoneInputRef}
            />
            {errors.phone && (
              <Typography sx={{ color: colors.pink, mt: "0.2rem" }} fontSize="0.8rem">
                {errors.phone.message}
              </Typography>
            )}
          </Box>
        </Box>

        <Input
          label="Inserisci informazioni aggiuntive sulla prenotazione (es: giorni e orario preferiti)"
          sx={{ width: "100%", mt: "0.5rem" }}
          name={fieldInfoName}
          onBlur={fieldInfoOnBlur}
          onChange={fieldInfoOnChange}
          inputRef={fieldInfoInputRef}
        />
        {errors.info && (
          <Typography sx={{ color: colors.pink, mt: "0.2rem" }} fontSize="0.8rem">
            {errors.info.message}
          </Typography>
        )}

        <Box sx={{ display: "flex", alignItems: "center", mt: "0.5rem", width: "100%" }}>
          <Checkbox
            sx={{ mr: "0.5rem" }}
            name={fieldTermsAndConditionsName}
            onBlur={fieldTermsAndConditionsOnBlur}
            onChange={fieldTermsAndConditionsOnChange}
            inputRef={fieldTermsAndConditionsInputRef}
          />
          <Typography sx={{ color: colors.white }}>
            <span>Accetto i</span>
            <a
              href="https://docs.google.com/document/d/e/2PACX-1vT4N1Efr98jfFa3rEP9rbhAD0CWmX9-FCUIAoWv299JtnQe4sGT1eAX0mi-Kdbj3W-G12x97tp9oqFK/pub"
              target="blank_"
              style={{ textDecoration: "none", marginLeft: "0.5rem", color: "#241C81", fontWeight: "bold" }}
            >
              Termini e le Condizioni
            </a>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FormExperienceBooking;
