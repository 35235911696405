import { Breakfast, Dinner, Lunch, Pingpong, Tennis, Videocall } from "../assets/images";

export const colors = {
  primary: "#E96666",
  secondary: "#9c4ec6",
  secondaryGradient: "linear-gradient(220deg, #900A96 0%, #f2295b 100%)",
  secondaryGradientBorder: "linear-gradient(220deg, #900A96, #f2295b) 1",
  title: "#FFAD25",
  white: "#fff",
  footer: "#382EAD",
  green: "#1AB269",
  black: "#171717",
  red: "#B21A1A",
  pink: "#ff6b7c",
};

export const etherscanURL = "https://etherscan.io/tx/";

export const nftExperienciesAddress = "0x5FbDB2315678afecb367f032d93F642f64180aa3";
export const giveawayAddress = "0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512";

export const fakeParticipants = [
  "0x71be63f3384f5fb98995898a86b02fb2426c5788",
  "0xfabb0ac9d68b0b445fb7357272ff202c5651694a",
  "0x1cbd3b2770909d4e10f157cabc84c7264073c9ec",
  "0xdf3e18d64bc6a983f673ab319ccae4f1a57c7097",
  "0xcd3b766ccdd6ae721141f452c550ca635964ce71",
  "0x2546bcd3c84621e976d8185a91a922ae77ecec30",
  "0xbda5747bfd65f08deb54cb465eb87d40e51b197e",
  "0xdd2fd4581271e230360230f9337d5c0430bf44c0",
  "0x8626f6940e2eb28930efb4cef49b2d1f2c9c1199",
];

export const montyExperiences = [
  {
    id: "1",
    title: "Breakfast at Monty’s",
    image: Breakfast,
    description: "Sii ospite di Monty per una colazione a Brighton/Londra",
  },
  {
    id: "2",
    title: "Lunch with Monty",
    image: Lunch,
    description: "Sii ospite di Monty per un pranzo a Brighton/Londra",
  },
  {
    id: "3",
    title: "Dinner with Monty",
    image: Dinner,
    description: "Sii ospite di Monty per una cena a Brighton/Londra",
  },
  {
    id: "4",
    title: "Ping Pong Match vs Monty",
    image: Pingpong,
    description: "Sfida Monty in una partita di ping pong",
  },
  {
    id: "5",
    title: "Tennis Match vs Monty",
    image: Tennis,
    description: "Azz... no, alla prossima!",
  },
  {
    id: "6",
    title: "Better Call Monty",
    image: Videocall,
    description:
      "Incontra Monty su Zoom per una call: un ottimo modo per salutarsi, presentare una tua idea o chiedergli un consiglio",
  },
];

export const cardTitles = {
  breakfast: "Colazione con Monty",
  lunch: "Pranzo con Monty",
  dinner: "Cena con Monty",
  "table-tennis-match": "Partita di Ping-Pong con Monty",
  "tennis-match": "Partita di Tennis con Monty",
  "5-min-call": "Call di 5 minuti con Monty",
  "10-min-call": "Call di 10 minuti con Monty",
  "30-min-call": "Call di 30 minuti con Monty",
  "60-min-call": "Call di 60 minuti con Monty",
  "non-existent": "Già riscattato!",
};
