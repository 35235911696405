import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";

const Tooltip = () => {
  return (
    <Container
      sx={{
        width: "330px",
        height: "150px",
        position: "absolute",
        top: "3.5rem",
        left: "-14rem",
        background: "#24187E",
        opacity: "0.85",
        padding: "24px 0",
      }}
    >
      <Box className="arrow-up" />
      <Typography color="white" variant="h5" fontWeight="bold" fontSize="1.5rem">
        Effettua prima il login
      </Typography>
      <Typography color="white" variant="body1" fontSize="1.125rem">
        Devi connettere il tuo wallet, tranquillo, non ti sarà addebitato nessun costo
      </Typography>
    </Container>
  );
};

export default Tooltip;
