import { doc } from "firebase/firestore";
import { useFirestoreDocument } from "@react-query-firebase/firestore";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import Button from "./Button";

import { db } from "../utility/firebase";
import { colors } from "../utility/constants";
import { getCardImageByType } from "../utility/utils";
import { Experience } from "../pages/Experiences";
import { useMemo } from "react";

interface CardExperienceBookingProps {
  experience: Experience;
  openDialog: () => void;
  setSelectedExperience: (experience: any) => void;
  isSingleExperience: boolean;
}

const CardExperienceBooking: React.FC<CardExperienceBookingProps> = ({
  experience,
  openDialog,
  setSelectedExperience,
  isSingleExperience,
}) => {
  const bookingRef = doc(db, "bookings", experience.id.toString());
  const booking = useFirestoreDocument(["bookings", experience.id.toString()], bookingRef, { subscribe: true });
  const snapshot = booking.data;
  const data = snapshot?.data();

  const isBooked = Boolean(data);

  const bookingStatus = useMemo(() => (data?.confirmed ? "Confermato" : "In Attesa"), [data]);

  return (
    <Grid item xs={6} sx={{ display: "flex" }}>
      {experience.type && (
        <img src={getCardImageByType(experience.type)} alt={experience.title} style={{ width: 250 }} />
      )}

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          padding: "1rem 0 1rem 1.5rem",
          width: "100%",
          maxWidth: `${isSingleExperience && "250px"}`,
        }}
      >
        <Box sx={{ flex: "100%", flexGrow: "1" }}>
          <Typography color="white" variant="h3" fontWeight="bold" fontSize="1.5rem" lineHeight={1.4}>
            {experience.title}
          </Typography>

          <Typography color="white" variant="body1" sx={{ marginTop: "1rem" }}>
            {experience.description}
          </Typography>
        </Box>

        <Box sx={{ marginTop: "0.5rem" }}>
          <Typography color="white" variant="h3" fontWeight="bold" fontSize="1.125rem" lineHeight={1.4}>
            {snapshot?.data() ? (
              <>
                <FontAwesomeIcon
                  icon={faCheck}
                  color={colors.green}
                  style={{
                    marginRight: "0.5rem",
                  }}
                />
                {bookingStatus}
              </>
            ) : (
              "Non ancora prenotato"
            )}
          </Typography>

          <Button
            text="Prenota"
            fullWidth
            onClick={() => {
              setSelectedExperience(experience);
              openDialog();
            }}
            otherSx={{ marginTop: "0.5rem" }}
            disabled={isBooked}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default CardExperienceBooking;
