import React, { useMemo, createContext, useReducer, useContext } from "react";
import type { ReactNode } from "react";

type State = { showLoginTooltip: boolean };
type Dispatch = (action: Action) => void;
type AppProviderProps = { children: ReactNode };
type Action = { type: "SET_SHOW_LOGIN_TOOLTIP"; payload: boolean };

const AppStateContext = createContext<{ state: State; dispatch: Dispatch } | undefined>(undefined);

const appReducer = (state: State, action: Action) => {
  switch (action.type) {
    case "SET_SHOW_LOGIN_TOOLTIP": {
      const showLoginTooltip = action.payload;

      return { showLoginTooltip };
    }
    default: {
      throw new Error(`Unhandled action type`);
    }
  }
};

const initialState = { showLoginTooltip: false };

const AppProvider = ({ children }: AppProviderProps) => {
  const [state, dispatch] = useReducer(appReducer, initialState);

  const memoizedValue = useMemo(() => ({ state, dispatch }), [state, dispatch]);
  return <AppStateContext.Provider value={memoizedValue}>{children}</AppStateContext.Provider>;
};

const useApp = () => {
  const context = useContext(AppStateContext);
  if (context === undefined) {
    throw new Error("useApp must be used within a AppProvider");
  }

  return context;
};

export { AppProvider, useApp };
