import React from "react";
import { Container, Box, Typography } from "@mui/material";
import { colors } from "../utility/constants";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faXmark } from "@fortawesome/free-solid-svg-icons";

interface AlertTextProps {
  type: "success" | "failed" | "booking";
}

const AlertText: React.FC<AlertTextProps> = ({ type }) => {
  const text = type === "success" || "booking" ? "Operazione Completata!" : "Qualcosa è andato storto, riprova";
  const backgroundIconColor = type === "success" || "booking" ? colors.green : colors.red;
  const icon = type === "success" || "booking" ? faCheck : faXmark;

  return (
    <Container
      sx={{
        display: "flex",
        width: "450px",
        height: "60px",
        borderRadius: "1rem",
        padding: "0 !important",
      }}
    >
      <Box
        sx={{
          backgroundColor: backgroundIconColor,
          display: "flex",
          borderRadius: "8px 0px 0px 8px",
          alignItems: "center",
          justifyContent: "center",
          width: "20%",
          height: "100%",
        }}
      >
        <FontAwesomeIcon size="2x" icon={icon} color="white" />
      </Box>
      <Box
        sx={{
          backgroundColor: colors.black,
          display: "flex",
          borderRadius: "0px 8px 8px 0px",
          alignItems: "center",
          justifyContent: "center",
          width: "80%",
          height: "100%",
        }}
      >
        <Typography fontWeight="900">{text}</Typography>
      </Box>
    </Container>
  );
};

export default AlertText;
