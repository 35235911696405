import React from "react";
import ReactDOM from "react-dom";
import { ToastContainer } from "react-toastify";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";

// * Web3
import { Provider, chain, createClient, createStorage } from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";

import { AppProvider } from "./context/app.context";

import App from "./App";
import reportWebVitals from "./reportWebVitals";

import theme from "./utility/theme";

import "./index.css";
import "react-toastify/dist/ReactToastify.css";

const infuraId = process.env.REACT_APP_INFURA_KEY;

const chains = [process.env.REACT_APP_CHAIN === "mainnet" ? chain.mainnet : chain.rinkeby];
const defaultChain = chain.rinkeby;

const client = createClient({
  storage: createStorage({ storage: window.localStorage }),
  autoConnect: true,
  connectors({ chainId }) {
    const chain = chains.find(x => x.id === chainId) ?? defaultChain;
    const rpcUrl = chain.rpcUrls.infura ? `${chain.rpcUrls.infura}/${infuraId}` : chain.rpcUrls.default;

    return [
      new MetaMaskConnector({ chains }),
      new WalletConnectConnector({
        chains,
        options: {
          qrcode: true,
          rpc: { [chain.id]: rpcUrl },
        },
      }),
      new CoinbaseWalletConnector({
        chains,
        options: { appName: "Monty Experiences", jsonRpcUrl: rpcUrl },
      }),
    ];
  },
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <Provider client={client}>
        <AppProvider>
          <ToastContainer />
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </AppProvider>
      </Provider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById("root"),
);

reportWebVitals();
