import { createTheme } from "@mui/material";
import { colors } from "./constants";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Mulish",
      "Roboto",
      "Helvetica Neue",
      "sans-serif",
      "Arial",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
    ].join(","),
  },
  components: {
    MuiInput: {
      styleOverrides: {
        root: {
          borderColor: "white",
          ":before": {
            borderImage: colors.secondaryGradientBorder,
          },
          ":hover:not(.Mui-disabled):before": {
            borderColor: "white",
          },
          ":after": {
            borderColor: colors.secondary,
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          color: "white",
          borderColor: "white",
        },
      },
    },
  },
});

export default theme;
