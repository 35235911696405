import { useEffect } from "react";
import { useAccount, useSignMessage } from "wagmi";
import { collection, doc } from "firebase/firestore";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import isEmpty from "lodash/isEmpty";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";

import FormExperienceBooking from "./FormExperienceBooking";
import Button from "./Button";
import PoweredBy from "./PoweredBy";

import { useValidation } from "../hooks";
import { db } from "../utility/firebase";

import { ReverseWaves } from "../assets/images";
import { useFirestoreDocumentMutation } from "@react-query-firebase/firestore";
import { usePrevious } from "../hooks";
import { getCardImageByType } from "../utility/utils";
import { Experience } from "../pages/Experiences";

interface DialogConnectProps {
  open: boolean;
  onClose: () => void;
  experience: Experience;
}

const DialogExperienceBooking: React.FC<DialogConnectProps> = ({ open, onClose, experience }) => {
  const { data: account } = useAccount();
  const { data: signature, signMessage, status: signStatus } = useSignMessage();
  const prevSignStatus = usePrevious(signStatus);

  const bookingsCollection = collection(db, "bookings");
  const ref = doc(bookingsCollection, experience.id);
  const mutation = useFirestoreDocumentMutation(ref);

  const { required, validateEmail } = useValidation();
  const bookingForm = {
    initialValues: {
      name: "",
      email: "",
      phone: "",
      info: "",
      termsAndConditions: false,
    },
    validationSchema: yup.object({
      name: required(yup.string()),
      email: required(validateEmail()),
      phone: required(yup.string()),
      info: yup.string(),
      termsAndConditions: required(yup.boolean()),
    }),
  };
  const { initialValues, validationSchema } = bookingForm;
  const resolver = yupResolver(validationSchema);

  const formState = useForm({
    defaultValues: initialValues,
    resolver,
    mode: "onChange",
  });
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = formState;

  const watchedFields = watch();
  const { name, email, phone, info, termsAndConditions } = watchedFields;

  const isFormError = !name || !email || !phone || !termsAndConditions || !isEmpty(errors);

  const handleSignMessage = async () => {
    signMessage({
      message: `By signing this message I confirm that Tech Alchemist can book an experience withdrawing NFT number ${experience.id.toString()}`,
    });
  };

  const onFormSubmit = () => handleSubmit(handleSignMessage)();

  useEffect(() => {
    if (prevSignStatus === "loading" && signStatus === "success") {
      const bookingsData = {
        user: {
          address: account?.address,
          name,
          email,
          phone,
        },
        title: experience.title,
        info,
        confirmed: false,
        signature: signature,
      };

      mutation.mutate(bookingsData);
      onClose();
    }
  }, [signStatus]);

  return (
    <Dialog onClose={onClose} open={open} fullWidth maxWidth="md">
      <Box
        sx={{
          height: "700px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          padding: "2rem",
          backgroundImage: `url(${ReverseWaves})`,
          backgroundSize: "cover",
        }}
      >
        <Typography variant="h4" fontSize="3rem" fontWeight="900" color="white">
          {experience.title}
        </Typography>

        <img
          style={{ width: 140, margin: "0.5rem 0" }}
          alt={experience.title}
          src={getCardImageByType(experience.type)}
        />

        <Box
          sx={{
            width: "80%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: "auto",
            flexGrow: "1",
          }}
        >
          <FormExperienceBooking register={register} errors={errors} />
        </Box>

        <Box sx={{ marginTop: "2rem" }}>
          <PoweredBy small />

          <Button
            text="Prenota"
            size="large"
            otherSx={{ marginTop: "2rem" }}
            disabled={isFormError}
            onClick={() => {
              onFormSubmit();
            }}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default DialogExperienceBooking;
