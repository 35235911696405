//import
import { Button } from "@mui/material";
import React from "react";

interface ButtonProps {
  text: string;
  size?: string;
  fullWidth?: boolean;
  otherSx?: any;
  onClick?: () => void;
  disabled?: boolean;
}

const MyButton: React.FC<ButtonProps> = ({
  text,
  onClick,
  size = "small",
  fullWidth = false,
  otherSx = "",
  disabled = false,
}) => {
  const width = fullWidth ? "100%" : size === "small" ? "8.75rem" : "25rem";
  const height = size === "small" ? "2.5rem" : "4.75rem";
  const fontsize = size === "small" ? "1.25rem" : "1.875rem";

  return (
    <Button
      variant="contained"
      sx={{
        width: width,
        height: height,
        fontWeight: "900",
        fontSize: fontsize,
        backgroundColor: "#D91C5B",
        borderRadius: "3rem",
        border: "0.2rem solid white",
        "&:hover": {
          backgroundColor: "#D91C5B",
        },
        opacity: disabled ? 0.5 : 1,
        ...otherSx,
      }}
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </Button>
  );
};

export default MyButton;
