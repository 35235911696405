//import
import { FocusEvent, ChangeEvent, Ref } from "react";
import { TextField } from "@mui/material";
interface InputProps {
  value?: string;
  classes?: string;
  label: string;
  sx?: { [key: string]: string };
  name: string;
  onBlur?: (e: FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  inputRef?: Ref<HTMLInputElement>;
}

const Input: React.FC<InputProps> = ({ value, classes, label, sx, name, onBlur, onChange, inputRef }) => {
  return (
    <TextField
      className={classes}
      label={label}
      value={value}
      variant="filled"
      InputLabelProps={{ style: { color: "rgba(255, 255, 255, 0.7)" } }}
      sx={{
        ...sx,
      }}
      name={name}
      onBlur={onBlur}
      onChange={onChange}
      inputRef={inputRef}
    />
  );
};
export default Input;
